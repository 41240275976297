<template>
  <div>
    <headertop></headertop>
    <div class="checkMap">
      <div class="flex">
        <div style="width: 100%; position: relative">
          <goolemap
            ref="goolemap"
            :heightauto="true"
            @mark="mark"
            @updateMaker="updateMaker"
            @getNowPlaceClick="getNowPlaceClick"
          ></goolemap>
        </div>
      </div>
      <!-- <div class="flex">
      </div> -->
    </div>
    <bottombox></bottombox>

    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <div class="left">
        <div class="input">
          <span style="margin-top: 0">地址：</span>
          <div class="flex">
            <el-input
              v-model="form.detailAddress"
              placeholder="请输入地址"
            ></el-input>
          </div>
        </div>
        <div class="input">
          <span>标签：</span>
          <el-input v-model="form.name" placeholder="请输入标签"></el-input>
        </div>
        <div class="input">
          <span>联系人：</span>
          <el-input
            v-model="form.linkName"
            placeholder="请输入联系人"
          ></el-input>
        </div>
        <div class="input">
          <span>电话：</span>
          <el-input
            v-model="form.linkPhone"
            placeholder="请输入电话"
            type="number"
          ></el-input>
        </div>

        <div class="btns">
          <span @click="save">保存</span>
          <span @click="dialogVisible = false" style="background: #ccc"
            >取消</span
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goolemap from "@/components/goolemap.vue";
import bottombox from "./components/bottombox.vue";
import headertop from "./components/header.vue";

import {
  memberCollectAddresslist,
  memberCollectAddressadd,
  membermemberCollectAddressdel,
  memberCollectAddressupdate,
} from "@/request/api.js";

export default {
  name: "home",
  components: {
    goolemap,
    bottombox,
    headertop
  },
  data() {
    return {
      dialogVisible: false,
      place: "",
      form: {
        latitude: "",
        longitude: "",
        detailAddress: "",
        name: "",
        linkPhone: "",
        linkName: "",
      },
      query: {
        pageSize: 5,
        pageNum: 1,
      },
      total: 0,
      tableData: [],
    };
  },
  created() {
    console.log(this.$store.state);
    this.form.longitude = this.$store.state.lng;
    this.form.latitude = this.$store.state.lat;
    this.place = this.form.detailAddress = this.$store.state.place;
  },

  methods: {
    clearAddress() {},
    currentChange(e) {
      this.query.pageNum = e;
    },
    mark(e) {
      this.place = this.form.detailAddress = e.title;
      this.form.longitude = e.longitude;
      this.form.latitude = e.latitude;
    },
    updateMaker(e) {
      console.log("经纬度");
      console.log(e);
    },
    tableClass({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        //奇数行，序号不能被2整除
        return "even-row";
      } else {
        return "";
      }
    },
    change(e) {
      this.form = JSON.parse(JSON.stringify(e));
      console.log(this.form);
      let initData = {
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        detailAddress: this.form.detailAddress,
      };
      this.$refs.goolemap.initPlace(initData);
    },
    qiehuan(e) {
      // this.$confirm("是否切换到该地址?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   let data = {
      //     lat: e.latitude,
      //     lng: e.longitude,
      //   };
      //   let initData = {
      //     latitude: e.latitude,
      //     longitude: e.longitude,
      //     detailAddress: e.name,
      //   };
      //   this.$refs.goolemap.initPlace(initData);
      //   this.$store.commit("setlatAndlng", data);
      //   this.$store.commit("setPlace", e.name);
      // });

      // console.log(this.$store.state);
    },
    qiehuan2() {
      // this.$confirm("是否切换到当前地址?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   let data = {
      //     lat: this.form.latitude,
      //     lng: this.form.longitude,
      //   };
      //   let initData = {
      //     latitude: this.form.latitude,
      //     longitude: this.form.longitude,
      //     detailAddress: this.place,
      //   };
      //   this.$refs.goolemap.initPlace(initData);
      //   this.$store.commit("setlatAndlng", data);
      //   this.$store.commit("setPlace", this.place);
      // });
    },
    getNowPlaceClick(){
      this.dialogVisible = true
    },
    save() {
      console.log(this.form);

      if (this.form.name == "") {
        return this.$message.warning("标签不能为空");
      }

      if (this.form.linkName == "") {
        return this.$message.warning("联系人不能为空");
      }

      if (this.form.linkPhone == "") {
        return this.$message.warning("联系电话不能为空");
      }

      if (
        this.form.detailAddress == "" ||
        this.form.latitude == "" ||
        this.form.longitude == ""
      ) {
        return this.$message.warning("地址不能为空");
      }

      if (this.form.id) {
        memberCollectAddressupdate(this.form).then((res) => {
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
          this.dialogVisible = false
        });
      } else {
        memberCollectAddressadd(this.form).then((res) => {
          this.$notify({
            title: "成功",
            message: "保存成功",
            type: "success",
          });
          this.dialogVisible = false

        });
      }
    },
    del(e) {
      membermemberCollectAddressdel(e.id).then((res) => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
        });
      });
    },
    shucang() {
      this.$message.success("收藏成功");
    },
  },
};
</script>

<style>
.even-row {
  background-color: #fff2f3 !important;
}
.vue-map-container {
 
}
</style>
<style lang="scss" scoped>
.table {
  box-sizing: border-box;
}
.checkMap {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  .flex {
    display: flex;
    justify-content: space-between;
    background: #fff;
  }

  .left {
    width: 40%;
    background: #fff;
    box-sizing: border-box;
    // padding-top: 15px;
    position: relative;
  }
  .right {
    width: 60%;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    // transform: translateY(-50px);
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      .iconfont {
        color: #e14343;
        font-size: 28px;
      }
    }
  }
}
.input {
  span {
    margin-top: 10px;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.btns {
  display: flex;
  margin-top: 20px;
  span {
    display: inline-block;
    width: 130px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ea4335;
    margin-right: 30px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 0 3px 3px #ccc;
  }
  // span:first-child {
  //   background: #357eea;
  // }
}
</style>